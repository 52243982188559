export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// At least 4 non-space characters
export const PASSWORD_REGEX = /^[\S]{4,100}$/;
// Letters, numbers, dashes, and underscores
export const USERNAME_REGEX = /^[a-zA-Z0-9\-_]+$/;
// Letters, numbers, dashes, underscores, and spaces
export const ACCOUNTNAME_REGEX = /^[a-zA-Z0-9\-_\s]+$/;

export const PRETTY_JOINT_NAMES = {
	leftKnee: "Left Knee",
	left_knee: "Left Knee",
	rightKnee: "Right Knee",
	right_knee: "Right Knee",
	Unknown: "Both Knees",
};
import React from "react";
import { Button, ListGroup, ListGroupItem } from "reactstrap";

import { formatDate } from "../services/HelperFunctions";

const ReportsListComponent = ({ reports, onReportSelect, onDeleteReport }) => {
	return (
		<ListGroup>
			{reports.map((report) => (
				<ListGroupItem
					key={report.reportId}
					action
					onClick={() => onReportSelect(report)}
					className="d-flex justify-content-between align-items-center"
				>
					{formatDate(report.reportDate)}{" "}
					<Button
						color="link"
						className="themed-link"
						onClick={(e) => {
							e.stopPropagation();
							onDeleteReport(report);
						}}
					>
						Delete{" "}
						<span className="icon-square flex-shrink-0">
							<i className={`bi bi-trash`} />
						</span>
					</Button>
				</ListGroupItem>
			))}
		</ListGroup>
	);
};

export default ReportsListComponent;

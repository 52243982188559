// Import dependencies
import React, { useContext, useEffect, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
	Collapse,
	Nav,
	NavItem,
	NavLink,
	Navbar,
	NavbarBrand,
	NavbarToggler,
} from "reactstrap";

// Import contexts
import { AppContext } from "../contexts/AppContext";

import logo from "../assets/RoMA-T_Cropped_Logo.png";
import { CheckToken } from "../services/APICalls";

const LOGGED_IN = process.env.REACT_APP_LOGGED_IN;

const Header = () => {
	// App context
	const { appState, setAppState } = useContext(AppContext);

	const [isOpen, setIsOpen] = useState(false);

	const toggleNavbar = () => setIsOpen(!isOpen);

	const handleLogout = () => {
		setAppState((prevState) => ({ ...prevState, authenticated: false }));
		localStorage.removeItem("userToken");
	};

	useEffect(() => {
		const checkToken = async () => {
			const userToken = localStorage.getItem("userToken");
			if (!appState.authenticated && userToken) {
				const { status, accountId, username } = await CheckToken(
					userToken
				);
				if (status === "Success") {
					setAppState((prevState) => ({
						...prevState,
						authenticated: true,
						accountId: accountId,
						username: username,
					}));
				} else {
					localStorage.removeItem("userToken");
				}
			}
		};
		checkToken();
	}, []);

	return (
		<Navbar expand="sm" color="primary" dark className="primary-navbar">
			{/* <Container className="d-flex justify-content-between"> */}
			<NavbarBrand tag={RouterNavLink} to="/">
				<img
					src={logo}
					alt="RoMA-T Logo"
					className="logo-image rounded-circle mx-2"
					width="50"
					height="50"
				/>
				<span className="d-none d-lg-inline">
					Range of Motion Analysis Tool
				</span>
				<span className="d-inline d-lg-none">RoMA-T</span>
			</NavbarBrand>

			<NavbarToggler onClick={toggleNavbar} />

			<Collapse className="flex-grow-0" isOpen={isOpen} navbar>
				<Nav className="" navbar>
					<NavItem>
						<NavLink tag={RouterNavLink} to="/">
							Home{" "}
							<span className="icon-square flex-shrink-0 d-none d-md-inline">
								<i className={`bi bi-house-door`} />
							</span>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink tag={RouterNavLink} to="/about">
							About{" "}
							<span className="icon-square flex-shrink-0 d-none d-md-inline">
								<i className={`bi bi-info-circle`} />
							</span>
						</NavLink>
					</NavItem>
					{appState.authenticated || LOGGED_IN ? (
						<>
							<NavItem>
								<NavLink tag={RouterNavLink} to="/submit">
									Submit{" "}
									<span className="icon-square flex-shrink-0 d-none d-md-inline">
										<i className={`bi bi-cloud-upload`} />
									</span>
								</NavLink>
							</NavItem>
							{/* <NavItem>
								<NavLink tag={RouterNavLink} to="/results">
									Results{" "}
									<span className="icon-square flex-shrink-0 d-none d-md-inline">
										<i className={`bi bi-bar-chart-line`} />
									</span>
								</NavLink>
							</NavItem> */}
							<NavItem>
								<NavLink
									tag={RouterNavLink}
									to="/profile"
									maxwidth="100px"
								>
									{appState.username || "Profile"}{" "}
									<span className="icon-square flex-shrink-0 d-none d-md-inline">
										<i className={`bi bi-person-circle`} />
									</span>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									tag={RouterNavLink}
									onClick={handleLogout}
									to="#"
									active={false}
									className="inactive-navbar-link"
								>
									Logout{" "}
									<span className="icon-square flex-shrink-0 d-none d-md-inline">
										<i
											className={`bi bi-box-arrow-right`}
										/>
									</span>
								</NavLink>
							</NavItem>
						</>
					) : (
						<NavItem>
							<NavLink tag={RouterNavLink} to="/login">
								Login{" "}
								<span className="icon-square flex-shrink-0 d-none d-md-inline">
									<i className={`bi bi-box-arrow-in-right`} />
								</span>
							</NavLink>
						</NavItem>
					)}
				</Nav>
			</Collapse>
			{/* </Container> */}
		</Navbar>
	);
};

export default Header;

import React from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";

import { formatDateShort } from "../services/HelperFunctions";

const ProgressChart = ({ reports }) => {
	// Prepare the data for the chart
	const data = reports.map((report) => ({
		date: new Date(report.reportDate), // Keep the original date for sorting
		Date: formatDateShort(report.reportDate), // Use the formatted date for display
		"Range of Motion": Math.round(report.maxAngle - report.minAngle),
	}));

	// Sort the data by date
	data.sort((a, b) => a.date - b.date);

	return (
		<ResponsiveContainer width="100%" height={400}>
			<LineChart
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis
					dataKey="Date"
					label={{
						value: "Date",
						position: "insideBottom",
						offset: -5,
					}}
				/>
				<YAxis
					domain={["dataMin - 10", "dataMax + 10"]}
					label={{
						value: "Range of Motion (°)",
						angle: -90,
						position: "insideLeft",
					}}
					// Add º symbol to the ticks
					tickFormatter={(value) => `${value}°`}
				/>{" "}
				{/* Adjust the domain for appropriate scale */}
				<Tooltip formatter={(value) => `${value}°`} />
				<Legend
					verticalAlign="top"
					align="right"
					iconType="line"
					iconSize={10}
					height={36}
				/>
				<Line
					type="monotone"
					dataKey="Range of Motion"
					title="Range of Motion"
					stroke="#bb301b"
					activeDot={{ r: 8 }}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};

export default ProgressChart;

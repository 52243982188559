import React from "react";
import { Container } from "reactstrap";

import logo from "../assets/RoMA-T_Full_Logo.png";

const Footer = () => {
	return (
		<footer className="bg-light py-4">
			<Container className="col-12 col-lg-8 my-4">
				<div className="row">
					<div className="col">
						<div className="d-flex">
							<img
								src={logo}
								alt="RoMA-T Logo"
								style={{
									maxHeight: "100px",
									marginRight: "20px",
								}}
							/>
							<div className="text-left">
								<p className="text-muted">
									&copy; {new Date().getFullYear()} Pitt
									HexAI. All rights reserved.
								</p>
								<p>
									A project of the{" "}
									<a
										href="https://pitthexai.github.io"
										className="themed-link"
										target="_blank"
										rel="noopener noreferrer"
									>
										Pitt HexAI Research Lab
									</a>
								</p>
								<p>
									Website by{" "}
									<a
										href="http://www.breitest.com"
										className="themed-link"
										target="_blank"
										rel="noopener noreferrer"
									>
										Joel Breit{" "}
										<span role="img" aria-label="tomato">
											🍅
										</span>
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</footer>
	);
};

export default Footer;

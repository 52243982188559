// Libraries
import React, { useContext, useEffect, useState } from "react";
import {
	Alert,
	Col,
	Container,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from "reactstrap";

// Contexts
import { AppContext } from "../contexts/AppContext";

// Custom components
import ProgressChart from "./ProgressChart";
import ReportsListComponent from "./ReportsListComponent";
import ReportDetailsComponent from "./ReportDetailsComponent";
import {
	DeleteReport,
	getDownloadUrl,
	GetReportsByAccountId,
} from "../services/APICalls";

// Constants
import { PRETTY_JOINT_NAMES } from "../assets/Constants";

const ResultsPage = () => {
	const { appState } = useContext(AppContext);

	const [activeTab, setActiveTab] = useState("1");

	// const [results, setResults] = useState([]);
	const [resultsByJoint, setResultsByJoint] = useState({});
	const [message, setMessage] = useState({
		type: "",
		text: "",
	});

	const [currentReport, setCurrentReport] = useState(null);

	useEffect(() => {
		const loadResults = async (e) => {
			setMessage({
				type: "info",
				text: "Loading results...",
			});

			if (!appState.accountId) {
				setMessage({
					type: "danger",
					text: "You must be logged in to view results.",
				});
				return;
			}

			const response = await GetReportsByAccountId(appState.accountId);

			setMessage(response.message);

			const resultsWithUrls = await Promise.all(
				response.results.map(async (report) => {
					const maxAngleUrl = await getDownloadUrl(
						"roma-t.user-files",
						`${appState.accountId}/${report.reportId}/maxImage.png`
					);
					const minAngleUrl = await getDownloadUrl(
						"roma-t.user-files",
						`${appState.accountId}/${report.reportId}/minImage.png`
					);
					const videoUrl = await getDownloadUrl(
						"roma-t.user-files",
						`${appState.accountId}/${report.reportId}/video.mp4`
					);
					return {
						...report,
						maxAngleUrl,
						minAngleUrl,
						videoUrl,
					};
				})
			);

			// Change all the joint names to their PRETTY_JOINT_NAMES[jointName] values
			resultsWithUrls.forEach((result) => {
				result.joint = PRETTY_JOINT_NAMES[result.joint];
			});

			// Transform results to an object of joint names mapped to an array of the results for that joint
			const transformedResults = resultsWithUrls.reduce((acc, result) => {
				const joint = result.joint;
				if (!acc[joint]) {
					acc[joint] = [];
				}
				acc[joint].push(result);
				return acc;
			}, {});
			// Sort the results within each joint by report date
			Object.keys(transformedResults).forEach((joint) => {
				transformedResults[joint].sort((a, b) => {
					return new Date(b.reportDate) - new Date(a.reportDate);
				});
			});

			console.log("Transformed results:", transformedResults);
			setResultsByJoint(transformedResults);
		};

		if (appState.accountId) {
			loadResults();
		}
	}, [appState.accountId]);

	const handleDeleteReport = async (report) => {
		setMessage({
			type: "info",
			text: "Deleting report...",
		});
		setMessage(await DeleteReport(report.reportId, appState.accountId));
		setResultsByJoint((prevResults) => {
			const updatedResults = { ...prevResults };
			updatedResults[report.joint] = updatedResults[report.joint].filter(
				(r) => r.reportId !== report.reportId
			);
			return updatedResults;
		});
	};

	return (
		<Container className="mt-4">
			<Nav tabs className="mb-4">
				{resultsByJoint &&
					Object.keys(resultsByJoint).map((jointName, index) => (
						<NavItem key={index}>
							<NavLink
								className={
									activeTab === `${index + 1}`
										? "active active-nav-link"
										: "inactive-nav-link"
								}
								onClick={() => setActiveTab(`${index + 1}`)}
							>
								{`${jointName} Reports (${resultsByJoint[jointName].length})`}
							</NavLink>
						</NavItem>
					))}
			</Nav>
			<TabContent activeTab={activeTab}>
				{resultsByJoint &&
					Object.keys(resultsByJoint).map((jointName, index) => (
						<TabPane tabId={`${index + 1}`} key={index}>
							<Row>
								<Col className="mb-4">
									<ProgressChart
										reports={resultsByJoint[jointName]}
									/>
								</Col>
							</Row>
							<Row>
								<Col md="6" className="mb-4">
									<h2>Past Reports</h2>
									<ReportsListComponent
										reports={resultsByJoint[jointName]}
										onReportSelect={(report) =>
											setCurrentReport(report)
										}
										onDeleteReport={handleDeleteReport}
									/>
								</Col>
								<Col md="6" className="mb-4">
									<h2>Report Details</h2>
									{currentReport ? (
										<ReportDetailsComponent
											report={currentReport}
										/>
									) : (
										<p>Select a report to view details.</p>
									)}
								</Col>
							</Row>
						</TabPane>
					))}
			</TabContent>

			{message.text.length > 0 && (
				<Alert
					color={message.type}
					isOpen={message.text.length > 0}
					fade
				>
					{message.text}
				</Alert>
			)}
		</Container>
	);
};

export default ResultsPage;

import React from "react";
import { Container } from "reactstrap";

import Header from "../components/Header";
import Footer from "../components/Footer";

function About() {
	return (
		<div>
			<Header />
			<Container className="col-12 col-lg-8 my-4">
				<h1>About RoMA-T</h1>
				<p>
					The Range of Motion Analysis Tool, or RoMA-T (ROW-muh-TEE),
					is a web-based tool for automatically reporting individual's
					angles and ranges of motion in their joints. It is part of
					an ongoing project at the University of Pittsburgh's Health
					and Explainable AI (HexAI) Laboratory to make AI tools
					available for use in medical and healthcare settings.
				</p>
				<h2>Our Team</h2>
				<p>
					Our team, organized by the HexAI Lab, is composed of a PhD
					student researcher and a team of faculty advisors at the
					University of Pittsburgh and UPMC. The team includes:
					<ul>
						<li>Joel Breit</li>
						<li>Ahmad P. Tafti, PhD, FAMIA</li>
						<li>A.J. Yates, Jr., MD</li>
						<li>F. Johannes Plate, MD, PhD</li>
					</ul>
				</p>
			</Container>
			<Footer />
		</div>
	);
}

export default About;

import React from "react";
import { Container } from "reactstrap";

// Import contexts

import Footer from "../components/Footer";
import Header from "../components/Header";
import ProtectedContent from "../components/ProtectedContent";

import VideoSubmissionForm from "../components/VideoSubmissionForm";

const VideoSubmissionPage = () => {
	return (
		<div>
			<Header />
			<Container className="col-12 col-lg-8 my-4">
				<h1>Video Submission</h1>
				<p>
					Please fill out the form below to submit a video of your
					joint exercises.
				</p>
				<ProtectedContent>
					<VideoSubmissionForm />
				</ProtectedContent>
			</Container>
			<Footer />
		</div>
	);
};

export default VideoSubmissionPage;

// Libraries
import React, { useContext, useState } from "react";
import {
	Alert,
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Spinner
} from "reactstrap";

// Contexts
import { AppContext } from "../contexts/AppContext";
import { UpdateAccountDetails } from "../services/APICalls";

const EditProfileModal = ({ isEditProfileOpen, toggleEditProfile }) => {
	const { appState, setAppState } = useContext(AppContext);

	const [editingUsername, setEditingUsername] = useState(false);
	const [editingPassword, setEditingPassword] = useState(false);

	const [updatingUsername, setUpdatingUsername] = useState(false);
	const [updatingPassword, setUpdatingPassword] = useState(false);

	const [usernameInput, setUsernameInput] = useState(appState.username);
	const [passwordInput, setPasswordInput] = useState("");
	const [newPasswordInput, setNewPasswordInput] = useState("");
	const [confirmPasswordInput, setConfirmPasswordInput] = useState("");

	const [alert, setAlert] = useState({
		color: "",
		text: "",
	});

	const handleFormButtonClicked = async (
		currentValue,
		newValue,
		isEditing,
		setIsEditing,
		isCancel,
		setUpdating
	) => {
		setAlert({
			color: "",
			text: "",
		});

		if (isCancel) {
			// Cancel
			if (currentValue !== newValue) {
				setUsernameInput(currentValue);
			}
		} else if (isEditing) {
			// Save
			setAlert({
				color: "",
				text: "",
			});
			if (currentValue !== newValue) {
				setUpdating(true);
				const alert = await UpdateAccountDetails(
					appState.accountId,
					usernameInput,
					passwordInput,
					newPasswordInput
				);
				setAlert(alert);
				if (alert.color === "success" && usernameInput !== appState.username) {
					setAppState({
						...appState,
						username: usernameInput,
					});
				}
				setUpdating(false);
			}
		}

		setIsEditing(!isEditing);
	};

	const formButton = (
		currentValue,
		newValue,
		isEditing,
		setIsEditing,
		isCancel,
		isUpdating,
		setUpdating
	) => {
		return (
			<Col className={isCancel && !isEditing ? "d-none" : "d-flex col-2"}>
				<Button
					size="sm"
					className={
						isCancel
							? "danger-button-outline w-100"
							: isEditing
							? "success-button-outline w-100"
							: "primary-button-outline w-100"
					}
					onClick={() => {
						handleFormButtonClicked(
							currentValue,
							newValue,
							isEditing,
							setIsEditing,
							isCancel,
							setUpdating
						);
					}}
				>
					{isCancel ? (
						<i className="bi bi-x-lg" />
					) : isUpdating ? (
						<Spinner size="sm" color="light" />
					) : isEditing ? (
						<i className="bi bi-check-lg" />
					) : (
						<i className="bi bi-pencil" />
					)}
				</Button>
			</Col>
		);
	};

	return (
		<Modal
			isOpen={isEditProfileOpen}
			toggle={toggleEditProfile}
			className="edit-profile-modal"
		>
			<ModalHeader toggle={toggleEditProfile}>Edit Profile</ModalHeader>
			<ModalBody>
				<Form>
					<FormGroup>
						<Row>
							<Label
								for="username"
							>
								Username
							</Label>
							<Col
								className={
									editingUsername
										? "d-flex align-items-center col-8"
										: "d-flex align-items-center col-10"
								}
							>
								<Input
									type="text"
									name="username"
									id="username"
									placeholder="Username"
									value={usernameInput}
									disabled={!editingUsername}
									onChange={(e) => setUsernameInput(e.target.value)}
								/>
							</Col>
							{formButton(
								appState.username,
								usernameInput,
								editingUsername,
								setEditingUsername,
								true
							)}
							{formButton(
								appState.username,
								usernameInput,
								editingUsername,
								setEditingUsername,
								false,
								updatingUsername,
								setUpdatingUsername
							)}
						</Row>
					</FormGroup>
					<FormGroup>
						<Row>
							<Label for="password">
								{!editingPassword
									? "Password"
									: "Current Password"}
							</Label>
							<Col
								className={editingPassword ? "col-8" : "col-10"}
							>
								<Input
									type="password"
									name="password"
									id="password"
									placeholder="Password"
									value={passwordInput}
									disabled={!editingPassword}
									onChange={(e) => setPasswordInput(e.target.value)}
								/>
							</Col>

							{formButton(
								"",
								passwordInput,
								editingPassword,
								setEditingPassword,
								true
							)}
							{formButton(
								"",
								passwordInput,
								editingPassword,
								setEditingPassword,
								false,
								updatingPassword,
								setUpdatingPassword
							)}
						</Row>
					</FormGroup>
					{editingPassword && (
						<>
							<FormGroup>
								<Label for="new-password">New Password</Label>
								<Col className="col-10">
									<Input
										type="password"
										name="new-password"
										id="new-password"
										placeholder="New Password"
										value={newPasswordInput}
										disabled={!editingPassword}
										onChange={(e) => setNewPasswordInput(e.target.value)}
									/>
								</Col>
							</FormGroup>
							<FormGroup>
								<Label for="confirm-password">
									Confirm New Password
								</Label>
								<Col className="col-10">
									<Input
										type="password"
										name="confirm-password"
										id="confirm-password"
										placeholder="New Password"
										value={confirmPasswordInput}
										disabled={!editingPassword}
										onChange={(e) => setConfirmPasswordInput(e.target.value)}
									/>
								</Col>
							</FormGroup>
						</>
					)}
				</Form>
				{alert.text && (
					<Alert color={alert.color} fade>{alert.text}</Alert>
				)}
			</ModalBody>
		</Modal>
	);
};

export default EditProfileModal;

import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Alert,
	Button,
	Form,
	FormGroup,
	FormText,
	Input,
	Label,
} from "reactstrap";

// Import components
import FileUpload from "./FileUpload";

// Import helpers
import { generateId } from "../services/HelperFunctions";

// TODO Just move this into VideoSubmissionPage
const VideoSubmissionForm = () => {
	const navigate = useNavigate(); // Initialize navigate

	const currentDate = new Date().toISOString().substring(0, 10);
	const [joint, setJoint] = useState("left_knee");
	const [painLevel, setPainLevel] = useState(1);
	const [medication, setMedication] = useState("");
	const [date, setDate] = useState(currentDate);
	const [fileName, setFileName] = useState("");
	const [fileUrl, setFileUrl] = useState(null);
	const [file, setFile] = useState(null);

	const [errorMessage, setErrorMessage] = useState(null);

	// Analysis
	const videoRef = useRef(null);
	const canvasRef = useRef(null);

	const handleFileChange = (event) => {
		try {
			setErrorMessage(null);
			const newFile = event.target.files[0];
			setFile(newFile);
			if (newFile) {
				// Stores the file in memory on the user's browser
				const url = URL.createObjectURL(newFile);
				setFileUrl(url);

				// Set canvas height and width to match the video
				const video = document.createElement("video");
				video.src = URL.createObjectURL(newFile);
				video.onloadedmetadata = () => {
					canvasRef.current.width = video.videoWidth;
					canvasRef.current.height = video.videoHeight;
				};
			}
		} catch (error) {
			console.error("Unexpected error in handleFileChange:", error);
		}
	};

	const handleFormSubmit = async (event) => {
		const reportId = generateId();

		event.preventDefault();
		// Navigate to ConfirmationPage
		navigate("/confirmation", {
			state: {
				date,
				joint,
				file,
				painLevel,
				medication,
				reportId,
			},
		});
	};

	return (
		<div>
			<Form className="p-3" onSubmit={handleFormSubmit}>
				<h3>Video</h3>
				{/* Joint selector */}
				<FormGroup>
					<Label for="joints">Joint</Label>
					{/* For now, just left and/or right knee */}
					<Input
						type="select"
						name="joints"
						id="joints"
						// valid={joint && !errorMessage}
						required
						onChange={(e) => {
							setJoint(e.target.value);
						}}
					>
						<option value="left_knee">Left Knee</option>
						<option value="right_knee">Right Knee</option>
					</Input>
				</FormGroup>

				{/* Date selector */}
				<FormGroup>
					<Label for="date">Video Date</Label>
					<Input
						type="date"
						name="date"
						id="date"
						value={date}
						placeholder="Enter date"
						required
						// valid={date && !errorMessage}
						onChange={(e) => {
							setDate(e.target.value);
						}}
					/>
				</FormGroup>
				<FileUpload
					onFileNameChange={setFileName}
					onFileChange={handleFileChange}
				/>
				<hr />

				{/* KOOS, JR */}
				<h3>Stiffness</h3>
				<FormText>
					The following question concerns the amount of joint
					stiffness you have experienced during the last week in your
					knee. Stiffness is a sensation of restriction or slowness in
					the ease of which you move your knee joint.
				</FormText>
				<FormGroup>
					<Label for="pain">
						How severe is your knee stiffness after first wakening
						in the morning?
					</Label>
					<Input
						type="select"
						name="pain"
						id="pain"
						required
						onChange={(e) => {
							setPainLevel(e.target.value);
						}}
					>
						<option value="0">None</option>
						<option value="1">Mild</option>
						<option value="2">Moderate</option>
						<option value="3">Severe</option>
						<option value="4">Extreme</option>
					</Input>
				</FormGroup>
				<h3>Pain</h3>

				<FormGroup>
					<Label for="pain">General Pain Level</Label>
					<div>
						<FormText>
							On a scale of 0 to 10, with 0 meaning no pain and 10
							meaning the worst pain you can imagine, how much
							knee pain have you had this past week?
						</FormText>
					</div>
					<Input
						type="select"
						name="pain"
						id="pain"
						// valid={pain && !errorMessage}
						required
						onChange={(e) => {
							setPainLevel(e.target.value);
						}}
					>
						<option value="1">1 - No Pain</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
						<option value="6">6</option>
						<option value="7">7</option>
						<option value="8">8</option>
						<option value="9">9</option>
						<option value="10">10 - Extreme Pain</option>
					</Input>
				</FormGroup>
				<FormText>
					What amount of knee pain have you experienced in the last
					week during the following activities?
				</FormText>
				<FormGroup>
					<Label for="pain">Twisting/pivoting on your knee</Label>
					<Input
						type="select"
						name="pain"
						id="pain"
						required
						onChange={(e) => {
							setPainLevel(e.target.value);
						}}
					>
						<option value="0">None</option>
						<option value="1">Mild</option>
						<option value="2">Moderate</option>
						<option value="3">Severe</option>
						<option value="4">Extreme</option>
					</Input>
				</FormGroup>
				<FormGroup>
					<Label for="pain">Straightening knee fully</Label>
					<Input
						type="select"
						name="pain"
						id="pain"
						required
						onChange={(e) => {
							setPainLevel(e.target.value);
						}}
					>
						<option value="0">None</option>
						<option value="1">Mild</option>
						<option value="2">Moderate</option>
						<option value="3">Severe</option>
						<option value="4">Extreme</option>
					</Input>
				</FormGroup>
				<FormGroup>
					<Label for="pain">Going up or down stairs</Label>
					<Input
						type="select"
						name="pain"
						id="pain"
						required
						onChange={(e) => {
							setPainLevel(e.target.value);
						}}
					>
						<option value="0">None</option>
						<option value="1">Mild</option>
						<option value="2">Moderate</option>
						<option value="3">Severe</option>
						<option value="4">Extreme</option>
					</Input>
				</FormGroup>
				<FormGroup>
					<Label for="pain">Standing upright</Label>
					<Input
						type="select"
						name="pain"
						id="pain"
						required
						onChange={(e) => {
							setPainLevel(e.target.value);
						}}
					>
						<option value="0">None</option>
						<option value="1">Mild</option>
						<option value="2">Moderate</option>
						<option value="3">Severe</option>
						<option value="4">Extreme</option>
					</Input>
				</FormGroup>

				<h3>Function, daily living</h3>
				<FormText>
					The following questions concern your physical function. By
					this we mean your ability to move around and to look after
					yourself. For each of the following activities please
					indicate the degree of difficulty you have experienced in
					the last week due to your knee.
				</FormText>
				<FormGroup>
					<Label for="pain">Rising from sitting</Label>
					<Input
						type="select"
						name="pain"
						id="pain"
						required
						onChange={(e) => {
							setPainLevel(e.target.value);
						}}
					>
						<option value="0">None</option>
						<option value="1">Mild</option>
						<option value="2">Moderate</option>
						<option value="3">Severe</option>
						<option value="4">Extreme</option>
					</Input>
				</FormGroup>
				<FormGroup>
					<Label for="pain">Bending to floor/pick up an object</Label>
					<Input
						type="select"
						name="pain"
						id="pain"
						required
						onChange={(e) => {
							setPainLevel(e.target.value);
						}}
					>
						<option value="0">None</option>
						<option value="1">Mild</option>
						<option value="2">Moderate</option>
						<option value="3">Severe</option>
						<option value="4">Extreme</option>
					</Input>
				</FormGroup>

				<h3>Medication</h3>
				{/* Medication text field */}
				<FormGroup>
					<Label for="medication">
						Please list any medications you have been taking related
						to joint pain or mobility
					</Label>
					<Input
						type="text"
						name="medication"
						id="medication"
						placeholder="Enter medication"
						// valid={medication && !errorMessage}
						// required
						// valid={date && !errorMessage}
						onChange={(e) => {
							setMedication(e.target.value);
						}}
					/>
				</FormGroup>
				{errorMessage && (
					<Alert color="danger" fade>
						{errorMessage}
					</Alert>
				)}
				<FormGroup>
					<Button
						type="submit"
						color="primary"
						className="primary-button"
						block
						disabled={
							!date || !fileName || !fileUrl || !!errorMessage
						}
					>
						Submit Video
					</Button>
				</FormGroup>
			</Form>

			<video ref={videoRef} style={{ display: "none" }} muted />
			<canvas
				ref={canvasRef}
				style={{ display: "none" }}
				width="640"
				height="480"
			/>
		</div>
	);
};

export default VideoSubmissionForm;

import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Home = () => {
	return (
		<div className="home">
			<Header />
			<Container className="col-12 col-lg-8 my-4">
				<Row className="justify-content-center">
					<Col md={8} className="text-center">
						<h1>Welcome to RoMA-T</h1>
						<p>
							RoMA-T (Range of Motion Analysis Tool) is a
							web-based tool allowing patients to automatically
							report their joint angles and ranges of motion.
						</p>
						<p>
							This site allows users to view angle and range of
							motion data in the browser based on user-provided
							videos and allows them to save and track their
							progress over time.
						</p>
						<Button
							tag={Link}
							to="/submit"
							variant="primary"
							className="primary-button"
						>
							Try It Out{" "}
							<span className="icon-square flex-shrink-0">
								<i className={`bi bi-arrow-right`} />
							</span>
						</Button>
					</Col>
				</Row>
				<Row className="mt-5">
					<Col md={4} className="text-center">
						<h3>Ease of Use</h3>
						<p>
							RoMA-T aims to make recording and analyzing joint
							range of motion simple and convenient allowing users
							to upload their own videos on any device.
						</p>
					</Col>
					<Col md={4} className="text-center">
						<h3>Progress Tracking</h3>
						<p>
							RoMA-T includes visualization of range of motion
							progress and trends to help keep patients informed
							and motivated.
						</p>
					</Col>
					<Col md={4} className="text-center">
						<h3>AI-Driven Analysis</h3>
						<p>
							RoMA-T uses machine learning to automatically
							analyze joint angles and ranges of motion from
							recorded videos.
						</p>
					</Col>
				</Row>
			</Container>
			<Footer />
		</div>
	);
};

export default Home;

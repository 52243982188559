import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import { Button, Container, Progress } from "reactstrap";

import { AppContext } from "../contexts/AppContext";

import Footer from "../components/Footer";
import Header from "../components/Header";

import ReportDetailsComponent from "../components/ReportDetailsComponent";
import StatusBar from "../components/StatusBar";
import {
	AnalyzeVideo,
	getDownloadUrl,
	GetReportById,
	UpdateReport,
	uploadFileToS3,
} from "../services/APICalls";

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * ConfirmationPage component
 * Needs to be initialized with date, joint, and file
 * Should not be navigated to directly
 * Is triggered when a user successfully submits a video from the video submission form
 * Show progress of video upload and analysis
 * Progress starts with a report being added to DynamoDB
 * Then the video is uploaded to S3
 * Then the video is analyzed with a Lambda function that uses MediaPipe pose estimation
 * Then the report details are fetched from DynamoDB
 * The report details are displayed to the user
 */
const ConfirmationPage = () => {
	const { appState } = useContext(AppContext);

	const { state } = useLocation();
	const { date, joint, file, reportId, painLevel, medication } = state || {};

	const [percentComplete, setPercentComplete] = useState(0);
	const [stage, setStage] = useState("initializing");
	const [message, setMessage] = useState("Initializing report...");
	const [color, setColor] = useState("info");
	const updateStatus = (newStatus) => {
		console.debug("Updating status to:", newStatus);
		setStage(newStatus);
		setMessage(statusMessages[newStatus]);
		setColor(
			newStatus === "failed"
				? "danger"
				: newStatus === "complete"
				? "success"
				: "info"
		);
		setPercentComplete(percentages[newStatus]);
	};
	const [uploadUrls, setUploadUrls] = useState({});

	const [reportDetails, setReportDetails] = useState(null);

	const [timeToCreate, setTimeToCreate] = useState(0);

	const statusMessages = {
		initializing: "Initializing report...",
		uploading: "Uploading video...",
		analyzing: "Analyzing video...",
		gettingReportDetails: "Fetching report details...",
		complete: "Video analysis complete. Your report is ready.",
		failed: "Video analysis failed. Please try resubmitting.",
	};

	const percentages = {
		initializing: 5,
		uploading: 10,
		analyzing: 20,
		updatingReport: 75,
		gettingReportDetails: 90,
		complete: 100,
		failed: 0,
	};

	const initializeReport = async () => {
		console.time("Confirmation Workflow");
		console.time("initializeReport");
		if (!date || !joint || !file) {
			console.error("date:", date, "joint:", joint, "file:", file);
			updateStatus("failed");
			return;
		}
		try {
			const submissionData = {
				accountId: appState.accountId,
				reportId: reportId,
				reportDate: date,
				joint: joint,
				painLevel: painLevel,
				medication: medication,
			};

			console.debug("initializeReport submission data:", submissionData);

			const response = await fetch(`${apiUrl}/InitializeReport`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(submissionData),
			});

			const body = await response.json();

			console.debug("initializeReport response:", body);

			if (response.status === 200) {
				console.log("Upload URLs received:", body.message);
				updateStatus("uploading");
				setUploadUrls({
					video: body.videoUploadUrl,
				});
			} else {
				console.error("Error:", body.error);
				updateStatus("failed");
			}
		} catch (error) {
			console.error("Error during submission:", error);
			updateStatus("failed");
		}
		console.timeEnd("initializeReport");
	};

	const uploadVideo = async () => {
		console.time("uploadVideo");
		if (!uploadUrls.video) {
			console.error("No video URL");
			updateStatus("failed");
		}
		console.debug("URLs updated");

		if (uploadUrls.video) {
			const uploadSuccess = await uploadFileToS3(
				uploadUrls.video,
				file,
				"video/mp4",
				appState.accountId,
				reportId,
				joint
			);
			if (uploadSuccess) {
				updateStatus("analyzing");
			} else {
				console.error("Error uploading video");
				updateStatus("failed");
			}
		} else {
			console.warn("Video URL is null");
		}
		console.timeEnd("uploadVideo");
	};

	const analyzeVideo = async () => {
		console.time("analyzeVideo");
		const result = await AnalyzeVideo(appState.accountId, reportId, joint);
		console.debug("Analysis result:", result);
		if (result.status === 200) {
			updateStatus("updatingReport");
		} else {
			console.error("Error analyzing video", result);
			updateStatus("failed");
		}
		console.timeEnd("analyzeVideo");
	};

	const updateReport = async () => {
		console.time("updateReport");
		const updateResult = await UpdateReport(appState.accountId, reportId);
		console.debug("Update result:", updateResult);
		if (updateResult.type !== "success") {
			console.error("Error updating report", updateResult);
			updateStatus("failed");
		} else {
			updateStatus("gettingReportDetails");
		}
		console.timeEnd("updateReport");
	};

	const getReportDetails = async () => {
		console.time("getReportDetails");
		try {
			const response = await GetReportById(reportId);

			const maxAngleUrl = await getDownloadUrl(
				"roma-t.user-files",
				`${appState.accountId}/${reportId}/maxImage.png`
			);
			const minAngleUrl = await getDownloadUrl(
				"roma-t.user-files",
				`${appState.accountId}/${reportId}/minImage.png`
			);
			const videoUrl = await getDownloadUrl(
				"roma-t.user-files",
				`${appState.accountId}/${reportId}/video.mp4`
			);

			const report = {
				...response.report,
				maxAngleUrl,
				minAngleUrl,
				videoUrl,
				reportId,
				timeToCreate,
			};

			updateStatus("complete");
			setReportDetails(report);
		} catch (error) {
			console.error("Error fetching report details:", error);
			updateStatus("failed");
		}
		console.timeEnd("getReportDetails");
		setTimeToCreate(console.timeEnd("Confirmation Workflow"));
	};

	// triggers on render and when stage changes
	useEffect(() => {
		const executeAsyncCalls = async () => {
			switch (stage) {
				case "initializing":
					setMessage("Initializing report...");
					await initializeReport();
					break;
				case "uploading":
					setMessage("Uploading video...");
					await uploadVideo();
					break;
				case "analyzing":
					setMessage("Analyzing video...");
					await analyzeVideo();
					break;
				case "updatingReport":
					setMessage("Updating report...");
					await updateReport();
					break;
				case "gettingReportDetails":
					setMessage("Fetching report details...");
					await getReportDetails();
					break;
				case "complete":
					setMessage(
						"Video analysis complete. Your report is ready."
					);
					break;
				case "failed":
					setMessage(
						"Video analysis failed. Please try resubmitting."
					);
					break;
				default:
					console.error("Invalid stage:", stage);
			}
		};

		executeAsyncCalls();
	}, [stage]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Header />
			<Container className="col-12 col-lg-8 my-4">
				<h1>Processing Your Video</h1>
				<p>{message}</p>
				<Progress
					animated={percentComplete < 100}
					value={percentComplete}
					className="primary-progress-bar mb-3"
				>
					{percentComplete > 9 && `${percentComplete}%`}
				</Progress>
				<p>
					accountId: {appState.accountId}
					<br />
					reportId: {reportId}
				</p>
				<StatusBar status={stage} color={color} text={message} />
				{stage === "complete" && reportDetails && (
					<>
						<h2>Report Details</h2>
						<hr />
						<ReportDetailsComponent report={reportDetails} />
					</>
				)}
				{stage === "complete" ? (
					<div className="d-flex justify-content-between mt-4">
						<Link to="/submit">
							<Button className="primary-button-outline">
								Submit Another Video
							</Button>
						</Link>
						<Link to="/profile">
							<Button className="primary-button">
								View All Reports
							</Button>
						</Link>
					</div>
				) : (
					<div className="d-flex justify-content-between mt-4">
						<Link to="/submit">
							<Button className="primary-button-outline">
								Back
							</Button>
						</Link>
					</div>
				)}
			</Container>
			<Footer />
		</>
	);
};

export default ConfirmationPage;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";

import Header from "../components/Header";
import LoginForm from "../components/LoginForm";
import Footer from "../components/Footer";

function LoginPage() {
	const navigate = useNavigate();

	const redirect = () => {
		navigate("/");
	};

	return (
		<div>
			<Header />
			<Container className="col-12 col-lg-8 my-4">
				<h1>Login</h1>
				<LoginForm redirect={redirect} />
			</Container>
			<Footer />
		</div>
	);
}

export default LoginPage;

// Changes YYYY-MM-DD to mmm dd, yyyy
export const formatDate = (date) => {
	const [year, month, day] = date.split("-");
	const options = { year: "numeric", month: "short", day: "numeric" };
	return new Date(year, month - 1, day).toLocaleDateString(
		undefined,
		options
	);
};

// Changes YYYY-MM-DD to mmm dd
export const formatDateShort = (date) => {
	const [year, month, day] = date.split("-");
	const options = { month: "short", day: "numeric" };
	return new Date(year, month - 1, day).toLocaleDateString(
		undefined,
		options
	);
};

// Converts a string to a non-negative hash
export function hash(string) {
	let hash = 0;
	if (string.length === 0) return hash;
	for (let i = 0; i < string.length; i++) {
		let char = string.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash = hash & hash;
	}
	return Math.abs(hash).toString();
}

// Generates a unique(-ish) ID
export function generateId() {
	const timestamp = Date.now().toString(36); // Convert timestamp to base 36 for a compact representation
	// const randomString = Math.random().toString(36).substring(2, 8); // Generate a random string and take 6 characters
	// return `${timestamp}-${randomString}`;
	return `${timestamp}`;
}

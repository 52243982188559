// Import dependencies
import React from "react";

// Import components
import { Alert, Spinner } from "reactstrap";

// Status is initializing, uploading, analyzing, updatingReport, gettingReportDetails, complete, failed

const StatusBar = ({ status, color, text }) => {
	return (
		<>
			{text && (
				<Alert color={color} fade>
					{status === "complete" ? (
						// check icon
						<span className="icon-square flex-shrink-0 d-none d-md-inline">
							<i className={`bi bi-check-circle`} />
						</span>
					) : status === "failed" ? (
						// error icon
						<span className="icon-square flex-shrink-0 d-none d-md-inline">
							<i className={`bi bi-x-circle`} />
						</span>
					) : (
						<Spinner size="sm" color={color} className="me-2" />
					)}{" "}
					{text}
				</Alert>
			)}
		</>
	);
};

export default StatusBar;

import React from "react";
import {
	BrowserRouter,
	Route,
	Routes,
} from "react-router-dom";

import { AppProvider } from "./contexts/AppContext";

import Home from "./pages/Home";
import About from "./pages/About";
import ResultsPage from "./pages/ResultsPage";
import VideoSubmissionPage from "./pages/VideoSubmissionPage";
import ConfirmationPage from "./pages/ConfirmationPage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
	return (
		<AppProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/submit" element={<VideoSubmissionPage />} />
					<Route path="/profile" element={<ResultsPage />} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/confirmation" element={<ConfirmationPage />} />
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</BrowserRouter>
		</AppProvider>
	);
}

export default App;

// Libraries
import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	Col,
	Container,
	Row
} from "reactstrap";

// Contexts
import { AppContext } from "../contexts/AppContext";

// Components
import EditProfileModal from "../components/EditProfileModal";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ProtectedContent from "../components/ProtectedContent";
import ResultsComponent from "../components/ResultsComponent";

const ResultsPage = () => {
	const { appState } = useContext(AppContext);

	const pageTitle = "Results Overview";

	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);

	const toggleEditProfile = () => setIsEditProfileOpen(!isEditProfileOpen);

	return (
		<div className="results">
			<Header />
			<Container className="col-12 col-lg-8 my-4">
				<Row className="d-flex justify-content-between">
					<Col>
						<h1>
							<span className="icon-square flex-shrink-0 d-none d-md-inline">
								<i className={`bi bi-person-circle`} />
							</span>{" "}
							{appState.username || "Profile"}
						</h1>
					</Col>
					<Col className="d-flex justify-content-end align-items-center">
						<Button
							color="primary"
							className="primary-button-outline"
							onClick={toggleEditProfile}
						>
							Edit Profile{" "}
							<span className="icon-square flex-shrink-0">
								<i className={`bi bi-pencil-square`} />
							</span>
						</Button>
					</Col>
				</Row>
				<hr />
				<h1>Results Overview</h1>
				<ProtectedContent>
					<ResultsComponent />
				</ProtectedContent>
			</Container>
			<Footer />

			<EditProfileModal
				isEditProfileOpen={isEditProfileOpen}
				toggleEditProfile={toggleEditProfile}
			/>
		</div>
	);
};

export default ResultsPage;

import React, { useState } from "react";
import { Button, Alert, FormGroup, Input, Label } from "reactstrap";

const FileUpload = ({ onFileNameChange, onFileChange }) => {
	const [videoName, setVideoName] = useState("");
	const [fileAdded, setFileAdded] = useState(false);
	// TODO deal with cases where there are multiple errors and one of them is fixed
	const [errorMessage, setErrorMessage] = useState("");

	const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB

	const handleFileNameChange = (name) => {
		if (!name) {
			setErrorMessage("File name cannot be empty.");
		} else {
			setErrorMessage("");
		}
		setVideoName(name);
		onFileNameChange(name);
	};

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0]; // Get the first file from the selected files
		if (selectedFile) {
			if (selectedFile.size > MAX_FILE_SIZE) {
				setErrorMessage("File size exceeds the size limit.");
				return;
			}

			if (!selectedFile.type.startsWith("video/")) {
				setErrorMessage("Only video files are allowed.");
				return;
			}

			handleFileNameChange(selectedFile.name);
			setFileAdded(true);
			setErrorMessage("");
		}
		onFileChange(event);
	};

	const handleAddFileButtonClick = async () => {
		// Trigger the file input element to open the file picker dialog
		const fileInput = document.getElementById("fileInput");
		fileInput.click();
	};

	const handleFileRemove = () => {
		const fileInput = document.getElementById("fileInput");
		fileInput.value = "";
		handleFileNameChange("");
		setFileAdded(false);
		setErrorMessage("");
	};

	return (
		<FormGroup>
			<div>
				<Input
					type="file"
					id="fileInput"
					className="d-none"
					onChange={handleFileChange}
					accept="video/*"
					required
				/>
			</div>
			<div className="mb-2">
				<Label for="videoName">Video</Label>
				<Input
					type="text"
					id="videoName"
					className="form-control"
					placeholder="Attach a video"
					value={videoName}
					onChange={(e) => handleFileNameChange(e.target.value)}
					disabled={!fileAdded}
				/>
				{errorMessage && (
					<Alert color="danger" className="mt-2" fade>
						{errorMessage}
					</Alert>
				)}
			</div>
			<div className="mb-3">
				<Button
					color="danger"
					outline
					onClick={handleFileRemove}
					disabled={!fileAdded}
				>
					Remove File
				</Button>

				<Button
					className="primary-button ms-2"
					onClick={handleAddFileButtonClick}
				>
					Add File
				</Button>
			</div>
		</FormGroup>
	);
};

export default FileUpload;

import React, { useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardLink,
	CardText,
	CardTitle,
	Col,
	Modal,
	ModalBody,
	ModalHeader,
} from "reactstrap";

import { formatDate } from "../services/HelperFunctions";

const ReportDetailsComponent = ({ report }) => {
	const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
	const [currentImage, setCurrentImage] = useState("");

	const toggleImageModal = () => setImageModalIsOpen(!imageModalIsOpen);

	const openImage = (imageUrl) => {
		setCurrentImage(imageUrl);
		toggleImageModal();
	};

	const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
	const [currentVideo, setCurrentVideo] = useState("");

	const toggleVideoModal = () => setVideoModalIsOpen(!videoModalIsOpen);

	const openVideo = (videoUrl) => {
		setCurrentVideo(videoUrl);
		toggleVideoModal();
	};

	return (
		<div>
			<Card>
				<CardHeader>
					<CardTitle tag="h5">{`Report from ${formatDate(
						report.reportDate
					)}`}</CardTitle>
				</CardHeader>
				<CardBody>
					<Col className="d-flex justify-content-between">
						<CardText className="mb-0">
							Maximum Angle:{" "}
							{parseFloat(report.maxAngle).toFixed(1)}º
						</CardText>
						<CardLink
							onClick={() => openImage(report.maxAngleUrl)}
							className="themed-link"
						>
							View Image
						</CardLink>
					</Col>
					<Col className="d-flex justify-content-between">
						<CardText className="mb-0">
							Minimum Angle:{" "}
							{parseFloat(report.minAngle).toFixed(1)}º
						</CardText>
						<CardLink
							onClick={() => openImage(report.minAngleUrl)}
							className="themed-link"
						>
							View Image
						</CardLink>
					</Col>

					<CardText>
						Range of Motion:{" "}
						{parseFloat(report.maxAngle - report.minAngle).toFixed(
							1
						)}
						º{/* if reportId */}
						{report.reportId && (
							<>
								<br />
								Report ID: {report.reportId}
							</>
						)}
						{report.timeToCreate && (
							<>
								<br />
								Time to Create: {report.timeToCreate} ms
							</>
						)}
						{report.painLevel && (
							<>
								<br />
								Pain Level: {report.painLevel}
							</>
						)}
						{report.medication && (
							<>
								<br />
								Medication: {report.medication}
							</>
						)}
					</CardText>
				</CardBody>
				<CardFooter>
					<div className="button-container">
						<Button
							size="sm"
							onClick={() => openVideo(report.videoUrl)}
							className="primary-button"
						>
							View Video
						</Button>
					</div>
				</CardFooter>
			</Card>

			{/* Modal for displaying images */}
			<Modal isOpen={imageModalIsOpen} toggle={toggleImageModal}>
				<ModalHeader toggle={toggleImageModal}>Angle Image</ModalHeader>
				<ModalBody>
					<img
						src={currentImage}
						alt="Angle"
						style={{ width: "100%", height: "auto" }}
					/>
				</ModalBody>
			</Modal>

			{/* Modal for displaying videos */}
			<Modal isOpen={videoModalIsOpen} toggle={toggleVideoModal}>
				<ModalHeader toggle={toggleVideoModal}>Video</ModalHeader>
				<ModalBody>
					<video
						src={currentVideo}
						controls
						style={{ width: "100%", height: "auto" }}
					/>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default ReportDetailsComponent;

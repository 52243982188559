import { hash } from "./HelperFunctions";

const apiUrl = process.env.REACT_APP_API_URL;

export async function uploadFileToS3(preSignedUrl, file, contentType, accountId, reportId, joint) {
	const headers = {};
	// Only add the Content-Type header if contentType is provided
	if (contentType) {
		headers["Content-Type"] = contentType;
	}

	console.debug("uploadFileToS3 file:", file);

	const response = await fetch(preSignedUrl, {
		method: "PUT",
		body: file, // The file to be uploaded
		headers: headers,
	});

	console.debug("uploadFileToS3 response:", response);

	if (response.ok) {
		console.log("Upload successful");

		// AnalyzeVideo(accountId, reportId, joint);
	} else {
		console.error("Upload failed", response.statusText);
	}

	return response.ok;
}

export async function AnalyzeVideo(accountId, reportId, joint) {
	let response = {
		status: "",
		message: "",
	};
	try {
		response = await fetch(`${apiUrl}/AnalyzeVideo`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				bucket: "roma-t.user-files",
				userId: accountId,
				reportId: reportId,
				frame_step: 10,
				angles: [
					joint
				]
			}),
		});

		// Handle the response
		const body = await response.json();
		const statusCode = response.status;
		const responseMessage = body.message;

		if (statusCode === 200) {
			console.log("Video analysis successful");
		} else {
			console.error("Error in /AnalyzeVideo API call:", responseMessage);
		}

	} catch (error) {
		console.error("Error in /AnalyzeVideo API call:", error);
		response = {
			status: "Error",
			message: "error",
		};
	}
	return response;
}


export async function getDownloadUrl(bucketName, objectKey) {
	const response = await fetch(`${apiUrl}/getDownloadUrl`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			bucketName,
			objectKey,
		}),
	});

	const body = await response.json();
	return body.url;
}

export async function GetReportsByAccountId(accountId) {
	let message = {
		type: "",
		text: "",
	};
	let results = [];
	try {
		const response = await fetch(`${apiUrl}/GetReportsByAccountId`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				accountId: accountId,
			}),
		});

		// Handle the response
		const body = await response.json();
		const statusCode = response.status;
		const responseMessage = body.message;

		if (statusCode === 200) {
			console.log("Reports loaded successfully:");
			message = {
				type: "success",
				text: "Reports loaded successfully.",
			};
			results = body;
		} else if (statusCode === 404) {
			message = {
				type: "info",
				text: responseMessage || "No reports found",
			};
		} else {
			message = {
				type: "danger",
				text: responseMessage || body.error,
			};
		}
	} catch (error) {
		console.error("Error:", error);
		message = {
			type: "danger",
			text: "Unexpected error loading results",
		};
	} finally {
		return {
			message,
			results,
		};
	}
}

export async function DeleteReport(reportId, accountId) {
	let message = {
		type: "",
		text: "",
	};
	try {
		const response = await fetch(`${apiUrl}/DeleteReport`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				reportId: reportId,
				accountId: accountId,
			}),
		});

		// Handle the response
		const body = await response.json();
		const statusCode = response.status;
		const responseMessage = body.message;

		if (statusCode === 200) {
			console.log("Report deleted successfully");
			message = {
				type: "success",
				text: "Report deleted successfully",
			};
		} else {
			message = {
				type: "danger",
				text: responseMessage || body.error,
			};
		}
	} catch (error) {
		console.error("Error:", error);
		message = {
			type: "danger",
			text: "Unexpected error deleting video",
		};
	} finally {
		return message;
	}
}

export async function createAccount(accountId, username, password) {
	let status = "";
	try {
		const nycTime = new Date().toLocaleString("en-US", {
			timeZone: "America/New_York",
			hour12: false,
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		});
		const dateAndTime = `${new Date()
			.toISOString()
			.slice(0, 10)} ${nycTime}`;
		const hashedPassword = hash(password);
		const response = await fetch(`${apiUrl}/createAccount`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				accountId: accountId,
				username: username,
				password: hashedPassword,
				creationDate: dateAndTime,
			}),
		});

		// Handle the response
		const body = await response.json();
		const statusCode = response.status;

		if (statusCode === 200) {
			console.log("Account created successfully");
			status = "Success";
		} else {
			const error =
				body.error || "/createAccount encountered an unexpected error";
			console.error(
				`/createAccount returned status ${statusCode}:`,
				error
			);
			if (statusCode === 403) {
				status = "Username taken";
			} else if (statusCode === 500) {
				status = "Internal Server Error";
			} else {
				status = "Unrecognized error";
			}
		}
	} catch (error) {
		console.error(`Unexpected error creating account:`, error);
		status = "Unexpected error";
	}
	return status;
}

export async function login(username, password) {
	let response = {
		status: "",
		accountId: "",
	};
	try {
		const hashedPassword = hash(password);
		const apiResponse = await fetch(`${apiUrl}/login`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				username: username,
				password: hashedPassword,
			}),
		});

		// Handle the response
		const body = await apiResponse.json();
		const statusCode = apiResponse.status;

		if (statusCode === 200) {
			console.log("Login successful");
			response = {
				status: "Success",
				accountId: body.accountId,
			};
		} else {
			const error =
				body.error || "/login encountered an unexpected error";
			console.error(`/login returned status ${statusCode}:`, error);
			if (statusCode === 401) {
				response = {
					status: "Invalid credentials",
					accountId: "",
				};
			} else if (statusCode === 500) {
				response = {
					status: "Internal Server Error",
					accountId: "",
				};
			} else {
				response = {
					status: "Unrecognized error",
					accountId: "",
				};
			}
		}
	} catch (error) {
		console.error(`Unexpected error logging in:`, error);
		response = {
			status: "Unexpected error",
			accountId: "",
		};
	}
	return response;
}

export async function UpdateAccountDetails(
	accountId,
	newUsername,
	password,
	newPassword
) {
	let alert = {
		color: "",
		text: "",
	};
	try {
		const hashedPassword = hash(password);
		const hashedNewPassword = hash(newPassword);
		const apiResponse = await fetch(`${apiUrl}/UpdateAccountDetails`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				accountId: accountId,
				newUsername: newUsername,
				password: hashedPassword,
				newPassword: hashedNewPassword,
			}),
		});

		// Handle the response
		const body = await apiResponse.json();
		const statusCode = apiResponse.status;
		switch (statusCode) {
			case 200:
				console.log(`Account details updated successfully`);
				alert = {
					color: "success",
					text: body.message,
				};
				break;
			case 401:
				console.error("Invalid password");
				alert = {
					color: "danger",
					text: "Invalid password",
				};
				break;
			case 403:
				console.error("Username taken");
				alert = {
					color: "danger",
					text: "Username taken. Please choose a different username.",
				};
				break;
			default:
				console.error(
					`UpdateAccountDetails returned status ${statusCode}:`,
					body.message
				);
				alert = {
					color: "danger",
					text: "An unexpected error occurred. Consider reloading the page and trying again.",
				};
				break;
		}
	} catch (error) {
		console.error("Error:", error);
		alert = {
			color: "danger",
			text: "Unexpected error updating account details",
		};
	} finally {
		return alert;
	}
}

export async function GenerateToken(accountId) {
	let token = "";

	try {
		const response = await fetch(`${apiUrl}/GenerateToken`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				accountId: accountId,
			}),
		});

		// Handle the response
		const body = await response.json();
		const statusCode = response.status;
		const responseMessage = body.message;

		if (statusCode === 200) {
			console.log("Token generated successfully");
			token = body.token;
		} else {
			console.error(
				"GenerateToken returned status",
				statusCode,
				responseMessage
			);
		}
	} catch (error) {
		console.error("Error:", error);
	} finally {
		return token;
	}
}

export async function CheckToken(token) {
	let response = {
		status: "",
		accountId: "",
		username: "",
	};

	try {
		const apiResponse = await fetch(`${apiUrl}/CheckToken`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: token,
			}),
		});

		// Handle the response
		const body = await apiResponse.json();
		const statusCode = apiResponse.status;

		if (statusCode === 200) {
			console.log("Token is valid");
			response = {
				status: "Success",
				accountId: body.accountId,
				username: body.username,
			};
		} else {
			console.error(
				"CheckToken returned status",
				statusCode,
				body.message
			);
			if (statusCode === 440) {
				response = {
					status: "Token expired",
					accountId: "",
					username: "",
				};
			} else {
				response = {
					status: "Invalid token",
					accountId: "",
					username: "",
				};
			}
		}
	} catch (error) {
		console.error("Error:", error);
	} finally {
		return response;
	}
}

export async function UpdateToken(token) {
	let statusCode = 0;

	try {
		const response = await fetch(`${apiUrl}/UpdateToken`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: token,
			}),
		});

		// Handle the response
		statusCode = response.status;
		if (statusCode === 200) {
			console.log("Token updated successfully");
		} else {
			console.error("UpdateToken returned status", statusCode);
		}
	} catch (error) {
		console.error("Error:", error);
	} finally {
		return statusCode;
	}
}

export async function InitializeReport(submissionData) {
	let message = {
		type: "",
		text: "",
	};

	const response = await fetch(`${apiUrl}/InitializeReport`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(submissionData),
	});

	// Handle the response
	const body = await response.json();

	if (response.status === 200) {
		console.log("Upload URLs received:", body.message);
		// set upload url
	} else {
		console.error("Error:", body.error);
		// set error message
	}

	return message;
}

export async function UpdateReport(accountId, reportId) {
	console.log("UpdateReport API Call - accountId:", accountId, "reportId:", reportId);

	let message = {
		type: "",
		text: "",
	};

	const response = await fetch(`${apiUrl}/UpdateReport`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			accountId,
			reportId,
		}),
	});

	// Handle the response
	const body = await response.json();

	if (response.status === 200) {
		console.log("Report updated successfully:", body.message);
		message = {
			type: "success",
			text: body.message,
		};
	} else {
		console.error("Error:", body.error);
		message = {
			type: "danger",
			text: body.error,
		};
	}

	return message;
}

export async function GetReportById(reportId) {
	let message = {
		type: "",
		text: "",
	};
	let report = {};
	try {
		const response = await fetch(`${apiUrl}/GetReportById`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				reportId,
			}),
		});

		// Handle the response
		const body = await response.json();
		const statusCode = response.status;
		const responseMessage = body.message;

		if (statusCode === 200) {
			console.log("Report loaded successfully:");
			message = {
				type: "success",
				text: "Report loaded successfully.",
			};
			report = body;
		} else if (statusCode === 404) {
			message = {
				type: "info",
				text: responseMessage || "No report found",
			};
		} else {
			message = {
				type: "danger",
				text: responseMessage || body.error,
			};
		}
	} catch (error) {
		console.error("Error:", error);
		message = {
			type: "danger",
			text: "Unexpected error loading results",
		};
	} finally {
		return {
			message,
			report,
		};
	}
}
// Import dependencies
import React, { useContext, useState } from "react";

// Import components
import {
	Alert,
	Button,
	Col,
	Form,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Spinner,
	UncontrolledTooltip,
} from "reactstrap";

// Import contexts
import { AppContext } from "../contexts/AppContext";

// Import parameters
import { Username, Password } from "../assets/Validation";
import { generateId } from "../services/HelperFunctions";
import { GenerateToken, UpdateToken, createAccount } from "../services/APICalls";

const CreateAccountForm = () => {
	// App context
	const { setAppState } = useContext(AppContext);

	// Form fields
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	// Validation state
	const [feedback, setFeedback] = useState({
		username: "",
		password: "",
		confirmPassword: "",
	});

	// Form state
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const handleUsernameChange = (usernameInput) => {
		setError("");
		setFeedback({
			...feedback,
			username: "",
		});
		if (usernameInput.length > Username.MaxLength) {
			setFeedback({
				...feedback,
				username: Username.MaxLengthFeedback,
			});
		}
		if (Username.InvalidCharacterRegex.test(usernameInput)) {
			setFeedback({
				...feedback,
				username: Username.InvalidCharacterFeedback,
			});
		}
		setUsername(usernameInput);
	};

	const handlePasswordChange = (passwordInput) => {
		setError("");
		setFeedback({
			...feedback,
			password: "",
		});
		if (passwordInput.length > Password.MaxLength) {
			setFeedback({
				...feedback,
				password: Password.MaxLengthFeedback,
			});
		}
		if (Password.InvalidCharacterRegex.test(passwordInput)) {
			setFeedback({
				...feedback,
				password: Password.InvalidCharacterFeedback,
			});
		}
		if (feedback.confirmPassword === "Passwords do not match") {
			if (passwordInput === confirmPassword) {
				setFeedback({
					...feedback,
					password: "",
					confirmPassword: "",
				});
			}
		}
		setPassword(passwordInput);
	};

	const handleConfirmPasswordChange = (confirmPasswordInput) => {
		setError("");
		setFeedback({
			...feedback,
			confirmPassword: "",
		});
		if (confirmPasswordInput.length > Password.MaxLength) {
			setFeedback({
				...feedback,
				confirmPassword: Password.MaxLengthFeedback,
			});
		}
		if (Password.InvalidCharacterRegex.test(confirmPasswordInput)) {
			setFeedback({
				...feedback,
				confirmPassword: Password.InvalidCharacterFeedback,
			});
		}
		if (feedback.password === "Passwords do not match") {
			if (password === confirmPasswordInput) {
				setFeedback({
					...feedback,
					password: "",
					confirmPassword: "",
				});
			}
		}
		setConfirmPassword(confirmPasswordInput);
	};

	const handleCreateAccount = async (e) => {
		e.preventDefault();
		setError("");

		if (username.length < Username.MinLength) {
			setFeedback({
				...feedback,
				username: Username.MinLengthFeedback,
			});
			return;
		}
		if (password.length < Password.MinLength) {
			setFeedback({
				...feedback,
				password: Password.MinLengthFeedback,
			});
			return;
		}
		if (confirmPassword.length < Password.MinLength) {
			setFeedback({
				...feedback,
				confirmPassword: Password.MinLengthFeedback,
			});
			return;
		}
		if (password !== confirmPassword) {
			setFeedback({
				...feedback,
				password: "Passwords do not match",
				confirmPassword: "Passwords do not match",
			});
			return;
		}

		setLoading(true);

		const accountId = generateId();

		const status = await createAccount(accountId, username, password);
		setLoading(false);
		if (status === "Success") {
			setAppState({
				authenticated: true,
				accountId: accountId,
				username: username,
			});
			let UserToken = localStorage.getItem('userToken');
			if (UserToken) {
				const statusCode = await UpdateToken(UserToken);
				if (statusCode !== 200) {
					console.warn("Failed to generate token");
				}
			} else {
				const userToken = await GenerateToken(accountId);
				localStorage.setItem("userToken", userToken);
			}
		} else if (status === "Username taken") {
			setFeedback({
				...feedback,
				username: `A user with the username "${username}" already exists. Please login instead or try a different username.`,
			});
		} else {
			setError("An unexpected error occurred. Please try again.");
		}
	};

	return (
		<Form className="p-3" onSubmit={handleCreateAccount}>
			<p>Enter your information below to create an account.</p>

			<FormGroup>
				<Label for="username">
					Username{" "}
					<span className="icon-square flex-shrink-0">
						<i
							id="usernameTooltip"
							className={`bi bi-info-circle`}
						/>
					</span>
					<UncontrolledTooltip
						placement="right"
						target="usernameTooltip"
					>
						{Username.Tooltip}
					</UncontrolledTooltip>
				</Label>
				<Col>
					<Input
						type="text"
						name="username"
						id="username"
						value={username}
						invalid={feedback.username !== ""}
						placeholder="Enter username"
						required
						onChange={(e) => {
							handleUsernameChange(e.target.value);
						}}
					/>
					<FormFeedback>{feedback.username}</FormFeedback>
				</Col>
			</FormGroup>

			<FormGroup>
				<Label for="password">
					Password{" "}
					<span className="icon-square flex-shrink-0">
						<i
							id="passwordTooltip"
							className={`bi bi-info-circle`}
						/>
					</span>
					<UncontrolledTooltip
						placement="right"
						target="passwordTooltip"
					>
						{Password.Tooltip}
					</UncontrolledTooltip>
				</Label>
				<Col>
					<Input
						type="password"
						name="password"
						id="password"
						value={password}
						invalid={feedback.password !== ""}
						placeholder="Enter password"
						required
						onChange={(e) => {
							handlePasswordChange(e.target.value);
						}}
					/>
					<FormFeedback>{feedback.password}</FormFeedback>
				</Col>
			</FormGroup>

			<FormGroup>
				<Label for="confirmPassword">
					Confirm Password{" "}
					<span className="icon-square flex-shrink-0">
						<i
							id="confirmPasswordTooltip"
							className={`bi bi-info-circle`}
						/>
					</span>
					<UncontrolledTooltip
						placement="right"
						target="confirmPasswordTooltip"
					>
						{Password.Tooltip}
					</UncontrolledTooltip>
				</Label>
				<Col>
					<Input
						type="password"
						name="confirmPassword"
						id="confirmPassword"
						value={confirmPassword}
						invalid={feedback.confirmPassword !== ""}
						placeholder="Confirm password"
						required
						onChange={(e) => {
							handleConfirmPasswordChange(e.target.value);
						}}
					/>
					<FormFeedback>{feedback.confirmPassword}</FormFeedback>
				</Col>
			</FormGroup>

			{error && (
				<Alert color="danger" fade>
					{error}
				</Alert>
			)}

			<FormGroup>
				<Col sm={{ size: 5, offset: 7 }}>
					<Button
						type="submit"
						color="primary"
						className="primary-button"
						block
						disabled={
							!username ||
							!password ||
							!confirmPassword ||
							loading
						}
					>
						Create Account{" "}
						{loading && <Spinner size="sm" color="light" />}
					</Button>
				</Col>
			</FormGroup>
		</Form>
	);
};

export default CreateAccountForm;

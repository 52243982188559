import React from "react";
import { Container } from "reactstrap";

import Header from "../components/Header";
import Footer from "../components/Footer";

function NotFoundPage() {
	return (
		<div>
			<Header />
			<Container className="col-12 col-lg-8 my-4">
				<h1>404 Not Found</h1>
				<p>
					The page you are looking for does not exist. Please check
					the URL and try again.
				</p>
			</Container>
			<Footer />
		</div>
	);
}

export default NotFoundPage;
